import { AlertOptions, ProblemContext } from '@gmm/problem';

import { sendErrorToServer } from '../api';
import { attemptSubmitted } from '../api/attemptSubmitted/attemptSubmitted';
import { bannerState, problemState } from '../stores';
import { getGuid, getIsTest, getUsername } from '../stores/globalState';
import { problemJsonMap } from '../stores/problemJsonMap';
import { getMd5 } from '../stores/problemStore';
import { studentAppModalState } from '../stores/studentAppModalStore';
import { userState } from '../stores/userStore';
import { getThemeColor } from '../types';
import {
  harvestTimeSeen,
  logHistory,
  processAjaxFailure,
} from '../utils/gmmUtils';

import { activity } from './activityMonitor';

function isUseNativeKeyboard(): boolean | undefined {
  return bannerState().useNativeKeyboard;
}

function isForceTouchKeyboard(): boolean | undefined {
  return bannerState().touchKeyboard;
}

function setCurrentUncertain(): void {
  const problems = problemState().problems;
  const selectedID = problemState().selectedID;

  if (!problems[selectedID]) {
    const msg =
      '[setCurrentUncertain] No box for current problem id: ' + selectedID;

    sendErrorToServer(msg);

    return;
  }

  problemState().updateProblem(selectedID, {
    uncertain: true,
  });
}

const errorToServer = (msg: string): void => {
  sendErrorToServer(msg);
};

const isStudent = (): boolean => true;

const getSS = (): number => userState().redisSessionId;

const isPermitReadAloud = (): boolean => bannerState().permitReadAloud;
const setLoading = (loading: boolean): void =>
  studentAppModalState().setLoading(loading, 'Submit');

const addStackableDialog = (dialog: AlertOptions): void => {
  studentAppModalState().addStackableDialog(dialog);
};

const getColor = (): string => {
  const themeOption = bannerState().themeOption;
  const themeColor = getThemeColor(themeOption);

  return themeColor.replace('rgb', 'rgba').replace(')', ', 0.75)');
};

// Provides the Student App as the implementation of ProblemContext for package @gmm/problem.
export const problemContext: ProblemContext = {
  activity,
  attemptSubmitted,
  errorToServer,
  getGuid: (): string => getGuid(),
  getMd5,
  getProblems: (): any => problemJsonMap,
  getSS,
  getUsername: (): string => getUsername(),
  harvestTimeSeen,
  isForceTouchKeyboard,
  isPermitReadAloud,
  isStudent,
  isTesting: (): boolean => getIsTest(),
  isUseNativeKeyboard,
  logHistory,
  processAjaxFailure,
  setCurrentUncertain,
  setLoading,
  addStackableDialog,
  getThemeColor: getColor,
};
